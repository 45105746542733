import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import './App.css';
import segImage from './images/seg.png';
import segImage2 from './images/hack.jpg';
import segImage3 from './images/hack2.jpeg';
import segImage4 from './images/hack3.jpeg';


function App() {
  return (
    <Router>
      <div className="App">
        {/* Barra de navegación */}
        <nav className="navbar">
          <div className="navbar-left">
            Urrutia Guevara Jousef Antonio
          </div>
          <div className="navbar-right">
            <Link to="/" className="nav-link">
              <button>Inicio</button>
            </Link>
            <Link to="/seccion1" className="nav-link">
              <button>Sección 1</button>
            </Link>
            <Link to="/seccion2" className="nav-link">
              <button>Sección 2</button>
            </Link>
            <Link to="/seccion3" className="nav-link">
              <button>Sección 3</button>
            </Link>
          </div>
        </nav>

        {/* Definición de Rutas */}
        <Routes>
          <Route path="/" element={<Inicio />} />
          <Route path="/seccion1" element={<Seccion1 />} />
          <Route path="/seccion2" element={<Seccion2 />} />
          <Route path="/seccion3" element={<Seccion3 />} />
        </Routes>
      </div>
    </Router>
  );
}

// Componente reutilizable de Acordeón
function AccordionItem({ title, content }) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={`accordion-item ${isOpen ? 'open' : ''}`}>
      <div className="accordion-title" onClick={() => setIsOpen(!isOpen)}>
        <h2>{title}</h2>
        <span>{isOpen ? '-' : '+'}</span>
      </div>
      {isOpen && <div className="accordion-content">{content}</div>}
    </div>
  );
}

// Componente Sección Inicio
function Inicio() {
  return (
    <div className="inicio-container">
      {/* Sección principal */}
      <div className="inicio-content">
        <div className="inicio-image">
          <img
            src={segImage}
            alt="Seguridad de la Información"
          />
        </div>
        <div className="inicio-text">
          <h1>Seguridad de la Información</h1>
          <p>
          La seguridad de la información es el conjunto de prácticas, políticas y procedimientos diseñados para proteger la confidencialidad, integridad y disponibilidad de la información, tanto digital como física. Su objetivo es prevenir el acceso no autorizado, la alteración, destrucción o divulgación de datos, y garantizar que la información esté accesible y utilizable cuando sea necesario.
          </p>
          <button className="inicio-button">Inicio</button>
        </div>
      </div>

      {/* Galería de imágenes */}
      <div className="inicio-gallery">
      <div className="gallery-item">
      <p>Ir a Sección 1: Ataques Cibernéticos</p>
      <Link to="/seccion1">
        <img
          src={segImage2}
          alt="Seccion 1"
          className="gallery-image"
        />
        </Link>
        </div>
        <div className="gallery-item">
        <p>Ir a Sección 2: Confidencialidad de la Información</p>
        <Link to="/seccion2">
        <img
          src={segImage3}
          alt="Seccion 2"
          className="gallery-image"
        />
        </Link>
        </div>
        <div className="gallery-item">
        <p>Ir a Sección 3: Principios de Seguridad</p>
        <Link to="/seccion3">
        <img
          src={segImage4}
          alt="Seccion 3"
          className="gallery-image"
        />
        </Link>
        </div>
      </div>
    </div>
  );
}




// Componente Sección 1 - Ataques Cibernéticos
function Seccion1() {
  const ataques = [
    { title: "DDoS", content: `Un ataque de denegación de servicio distribuido (DDoS) es un intento malicioso de interrumpir el tráfico normal de un servidor, servicio o red, enviando una avalancha de solicitudes falsas. Estas solicitudes agotan los recursos del sistema, haciéndolo incapaz de responder a solicitudes legítimas. Los ataques DDoS suelen utilizar redes de dispositivos comprometidos, conocidos como botnets, para enviar la avalancha de tráfico, dificultando su detección y mitigación.` },
    { title: "Ordenador Zombie", content: `Un ordenador zombie es un dispositivo comprometido que ha sido controlado de manera remota por un atacante sin el conocimiento del propietario. Los ordenadores zombies son generalmente parte de una botnet y se utilizan para llevar a cabo actividades maliciosas como el envío de spam, ataques DDoS, o el robo de datos, sin dejar rastro evidente de la actividad en el dispositivo afectado.` },
    { title: "Ciberdelincuente", content: `Un ciberdelincuente es una persona o grupo que utiliza la tecnología y la red para cometer actos ilícitos. Entre sus actividades se incluyen el robo de información personal, el fraude financiero, la extorsión mediante ransomware y otros tipos de malware, la explotación de vulnerabilidades de seguridad, y la distribución de software malicioso. Los ciberdelincuentes actúan motivados por beneficios económicos, políticos o personales.` },
    { title: "Botmaster", content: `El botmaster es la persona o grupo que controla una botnet, que es una red de dispositivos comprometidos que son usados para realizar diversas actividades ilícitas. Los botmasters utilizan software especializado para tomar control de los dispositivos, y luego los dirigen para enviar spam, lanzar ataques DDoS, realizar fraudes en línea o propagar malware.` },
    { title: "Botnet", content: `Una botnet es una red de dispositivos conectados a Internet que han sido comprometidos y controlados remotamente por un atacante. Los dispositivos afectados, conocidos como "zombies", se usan para realizar ataques coordinados, generalmente sin el conocimiento del propietario. Las botnets se usan para lanzar ataques DDoS, enviar correos electrónicos de spam a gran escala, propagar malware o realizar fraudes financieros.` },
    { title: "Ransomware", content: `El ransomware es un tipo de malware que cifra los archivos de una víctima o bloquea el acceso a su sistema, exigiendo un pago (ransom) para restaurar el acceso. Este tipo de malware ha crecido en popularidad debido a su potencial para generar grandes beneficios económicos para los ciberdelincuentes. Los ataques de ransomware son especialmente peligrosos para empresas y organismos públicos, ya que pueden paralizar operaciones enteras.` },
    { title: "Disclaimer", content: `En el contexto de la seguridad cibernética, un disclaimer es una advertencia o exención de responsabilidad utilizada para limitar las responsabilidades legales de los sitios web o servicios en caso de ataques o violaciones de seguridad. Aunque no evitan los ataques, los disclaimers pueden proteger a las organizaciones de litigios o demandas al dejar claro que no son responsables por daños causados por actores maliciosos.` },
    { title: "The Morris Worm", content: `El Morris Worm fue uno de los primeros gusanos informáticos conocidos, lanzado en 1988 por Robert Tappan Morris. Afectó a aproximadamente el 10% de los ordenadores conectados a Internet en ese momento, causando grandes problemas. Este gusano era capaz de replicarse y propagarse automáticamente, sobrecargando los sistemas y causando fallos. El Morris Worm resaltó la necesidad de una mayor seguridad en los sistemas conectados a Internet.` },
    { title: "Ciberwarfare", content: `La ciberwarfare (o guerra cibernética) se refiere a los conflictos armados que se llevan a cabo a través del ciberespacio. En lugar de confrontaciones físicas, la ciberwarfare implica el uso de malware, ataques DDoS, espionaje, y sabotajes para interrumpir infraestructuras críticas, robar datos sensibles, o desestabilizar a los enemigos. Este tipo de guerra se utiliza cada vez más como herramienta en conflictos entre naciones.` },
    { title: "Stuxnet", content: `Stuxnet es un gusano informático descubierto en 2010 que fue diseñado específicamente para sabotear instalaciones industriales. Se cree que fue creado por EE. UU. e Israel con el objetivo de dañar las instalaciones nucleares iraníes. Stuxnet es uno de los primeros ejemplos de ciberarmas utilizadas en el mundo real y subraya cómo el malware puede ser usado con fines políticos y militares.` },
    { title: "Moonlight Maze", content: `Moonlight Maze fue una de las primeras campañas documentadas de ciberespionaje a gran escala. Descubierta en 1999, se cree que fue responsable del robo de información confidencial de agencias gubernamentales, instituciones académicas y empresas en los Estados Unidos. El ataque reveló la vulnerabilidad de los sistemas informáticos gubernamentales y subrayó la creciente importancia de la seguridad cibernética.` },
    { title: "Operation Aurora", content: `Operation Aurora fue una serie de ataques cibernéticos dirigidos contra grandes empresas de tecnología, incluidas Google y Adobe, en 2009 y 2010. Los atacantes usaron métodos avanzados para obtener acceso a propiedad intelectual sensible y correos electrónicos de empleados. Se sospecha que los ataques fueron patrocinados por el estado chino, lo que destacó la amenaza de los ataques patrocinados por estados-nación.` },
    { title: "Freeze it into submission", content: `Esta técnica de ataque consiste en sobrecargar un sistema o servidor con un gran volumen de solicitudes o datos hasta que el sistema se congela o se bloquea, lo que resulta en una denegación de servicio. Es similar a un ataque DDoS y se utiliza para deshabilitar temporalmente sitios web o servidores.` },
    { title: "Wannacry", content: `Wannacry es un ransomware que, en 2017, afectó a miles de organizaciones en todo el mundo, incluyendo hospitales, grandes corporaciones y agencias gubernamentales. Este ransomware se propagaba utilizando una vulnerabilidad en el sistema operativo Windows. Una vez infectados, los sistemas eran bloqueados y se pedía un rescate en criptomonedas para restaurar el acceso.` },
    { title: "Petya", content: `Petya es un tipo de ransomware que encripta el registro de arranque maestro (MBR) del disco duro de la víctima, lo que impide que el sistema operativo se inicie. Fue utilizado en ataques a gran escala en 2016 y 2017, afectando principalmente a empresas e infraestructuras críticas, y provocando grandes pérdidas económicas.` },
    { title: "Equifax (ataque)", content: `El ataque a Equifax en 2017 es uno de los mayores robos de datos personales en la historia. Los atacantes robaron la información de más de 140 millones de personas, incluidos nombres, números de Seguro Social y otros datos sensibles. Este ataque demostró la vulnerabilidad de grandes empresas a ciberataques y subrayó la importancia de la protección de datos.` },
    { title: "Cam4 (ataque)", content: `En 2020, el sitio web de transmisión de videos para adultos Cam4 sufrió una violación masiva de datos, en la cual se expusieron más de 10 mil millones de registros. Estos incluían información personal como direcciones IP, correos electrónicos y conversaciones en línea. Este ataque subrayó la importancia de la seguridad de datos personales en plataformas de alto tráfico.` }
  ];

  return (
    <div className="main-section">
      <h1>Ataques Cibernéticos</h1>
      <div className="accordion">
        {ataques.map((ataque, index) => (
          <AccordionItem key={index} title={ataque.title} content={ataque.content} />
        ))}
      </div>
    </div>
  );
}


// Componente Sección 2 - Confidencialidad de la Información
function Seccion2() {
  const confidencialidad = [
    { title: "Ley General de Protección de Datos Personales de México", content: `Esta ley mexicana establece los principios y procedimientos para proteger los datos personales, garantizando que las entidades públicas y privadas que los manejen cumplan con las regulaciones necesarias para su protección. También permite a los ciudadanos controlar el uso de sus datos personales y establece sanciones para las violaciones.` },
    { title: "Ley de Propiedad Industrial", content: `Esta ley protege los derechos de propiedad industrial en México, incluyendo patentes, marcas, nombres comerciales, diseños industriales, y secretos industriales. La ley está diseñada para fomentar la innovación y proteger a los inventores y empresas, asegurando que sus invenciones y activos intelectuales estén protegidos de uso no autorizado.` },
    { title: "Ley Federal de Derechos de Autor", content: `La Ley Federal de Derechos de Autor en México protege las creaciones literarias, artísticas y científicas de los autores. Establece los derechos exclusivos de los autores para controlar la distribución, reproducción y modificación de sus obras. Esta ley también incluye sanciones para quienes infrinjan los derechos de autor.` },
    { title: "Ley Federal de Datos Personales en Posesión de Particulares", content: `Esta ley regula la protección de los datos personales que están en posesión de particulares, como empresas privadas, y establece las bases para garantizar la privacidad de los individuos. La ley se aplica en todos los sectores económicos y prevé multas por el mal manejo de la información.` },
    { title: "Código Penal Federal", content: `El Código Penal Federal mexicano incluye disposiciones relacionadas con los delitos cibernéticos y la protección de datos personales. Establece sanciones para actividades como el acceso no autorizado a sistemas informáticos, la manipulación de información o la divulgación indebida de datos personales.` },
    { title: "Ley General de Transparencia y Acceso a la Información", content: `Esta ley garantiza que los ciudadanos mexicanos tengan el derecho de acceder a la información pública, fomentando la transparencia en las instituciones gubernamentales. También establece mecanismos para que las entidades públicas protejan los datos personales mientras cumplen con las solicitudes de acceso a la información.` },
    { title: "Criptografía Simétrica", content: `La criptografía simétrica es un método de cifrado donde la misma clave se utiliza tanto para cifrar como para descifrar la información. Es rápida y eficiente para grandes volúmenes de datos, pero presenta desafíos de seguridad debido a la necesidad de mantener la clave secreta entre las partes que comparten la comunicación.` },
    { title: "Criptografía Asimétrica", content: `La criptografía asimétrica utiliza dos claves distintas: una clave pública, que se puede compartir libremente, y una clave privada, que se mantiene en secreto. Este método es más seguro para transmitir datos, ya que permite que las personas cifren mensajes con la clave pública, pero solo el destinatario con la clave privada puede descifrarlo.` },
    { title: "Cifrado por Bloques", content: `El cifrado por bloques es un método de cifrado que toma la información y la divide en bloques de tamaño fijo antes de cifrarla. Cada bloque se cifra por separado, lo que garantiza que incluso si una parte del mensaje es comprometida, el resto de la información sigue protegida.` },
    { title: "Cifrado por Flujo", content: `El cifrado por flujo cifra los datos bit por bit o byte por byte a medida que se transmiten. Este tipo de cifrado es útil en transmisiones en tiempo real y es eficiente para flujos continuos de datos, como videos en vivo o llamadas de voz.` },
    { title: "Criptoanálisis", content: `El criptoanálisis es el estudio de técnicas para descifrar información cifrada sin conocer la clave original. Los criptoanalistas utilizan métodos matemáticos para encontrar debilidades en los algoritmos de cifrado o en la implementación de estos, con el objetivo de romper la seguridad de la comunicación.` }
  ];

  return (
    <div className="main-section">
      <h1>Confidencialidad de la Información</h1>
      <div className="accordion">
        {confidencialidad.map((tema, index) => (
          <AccordionItem key={index} title={tema.title} content={tema.content} />
        ))}
      </div>
    </div>
  );
}


// Componente Sección 3 - Principios de Seguridad de la Información
function Seccion3() {
  const principios = [
    { title: "Accesibilidad", content: `La accesibilidad garantiza que los sistemas y la información estén disponibles para los usuarios autorizados cuando lo necesiten. Este principio asegura que los datos sean accesibles en todo momento, incluso durante fallos del sistema o desastres, mediante técnicas de redundancia, recuperación y tolerancia a fallos.` },
    { title: "Confidencialidad", content: `La confidencialidad implica que solo las personas autorizadas pueden acceder a la información sensible. Este principio se asegura de que la información no sea divulgada a personas no autorizadas y se aplica mediante el uso de técnicas de cifrado, control de acceso y políticas de manejo de la información.` },
    { title: "Disponibilidad", content: `La disponibilidad garantiza que la información y los sistemas estén accesibles y operativos cuando sean necesarios. Este principio requiere mantener la infraestructura en condiciones óptimas, con planes de contingencia para garantizar que los usuarios autorizados puedan acceder a la información sin interrupciones.` },
    { title: "Autenticación", content: `La autenticación es el proceso de verificar la identidad de los usuarios antes de permitirles el acceso a sistemas o datos. Los métodos de autenticación incluyen contraseñas, tokens de seguridad, autenticación biométrica y autenticación de múltiples factores, lo que garantiza que solo los usuarios autorizados accedan a los sistemas.` },
    { title: "Integridad", content: `La integridad asegura que la información no ha sido alterada o destruida de manera no autorizada. Para mantener la integridad de los datos, se utilizan técnicas como las sumas de verificación (hash), el control de versiones y la firma digital, que permiten verificar que los datos no han sido modificados desde su creación.` },
    { title: "Control de Acceso", content: `El control de acceso garantiza que solo las personas autorizadas tengan acceso a sistemas o datos específicos. Este principio se implementa mediante la gestión de permisos, políticas de control de acceso basado en roles (RBAC) y otros mecanismos de autorización, limitando el acceso a los recursos según las funciones y necesidades de los usuarios.` }
  ];

  return (
    <div className="main-section">
      <h1>Principios de Seguridad de la Información</h1>
      <div className="accordion">
        {principios.map((principio, index) => (
          <AccordionItem key={index} title={principio.title} content={principio.content} />
        ))}
      </div>
    </div>
  );
}


export default App;
